/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@3.1.5/dist/vue.global.min.js
 * - /npm/moment@2.29.1/moment.min.js
 * - /npm/moment@2.29.1/locale/ja.min.js
 * - /npm/moment-timezone@0.5.33/builds/moment-timezone-with-data.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
